
.impressum {
    text-align: center;

    h3 {
        margin-top: 10px;
        margin-bottom: 20px;
        color: #0066B3;
    }

    .row, div {
        text-align: left;
    }
}

