
.datenschutzerklaerung {

    div {
        text-align: left;
    }

    h3 {
        margin-top: 10px;
        margin-bottom: 20px;
        color: #0066B3;
    }

    ol, ul {

        padding-left: 12px;
        text-align: left;

        ol {
            list-style-type: lower-alpha;

            ul, ol {
                margin-top: 15px;
                margin-bottom: 15px;
            }

            li {
                margin-top: 10px;
                margin-bottom: 10px;
            }
        }
    }
}

.datenschutzerklaerung > .info-message > ol > li {
    &::marker {
        /*font: 17px/21px 'OfficinaSerifITCPro-Bold', 'Rockwell Bold', Rockwell, 'Courier Bold', Courier, Georgia, 'Liberation Serif', serif;*/
        color: #0066B3;
        font-size: 18px;
    }
}