%cube-shared {
  float: left;
  text-align: center;
  color: white;
  font-weight: bold;
  line-height: 17px;
  width: 20px;
  height: 20px;
}

.qr-code-cube-qualities {
  height: 45px;
  width: max-content;
  overflow: auto;
  padding: 0.8em;
  margin: auto;
  border: 1px solid grey;
  margin-top: 20px;


  .qr-green-cube {
    @extend %cube-shared;
    background: #13bf6d;
    border: 1px solid grey;
    margin: 1px;

    &:before {
      content: "\2713";
      color: #555;
    }
  }

  .qr-yellow-cube {
    @extend %cube-shared;
    background: #f6f600;
    border: 1px solid grey;
    margin: 1px;

    &:before {
      content: "-";
      color: #555;
    }
  }

  .qr-red-cube {
    @extend %cube-shared;
    background: #dc081b;
    border: 1px solid grey;
    margin: 1px;

    &:before {
      content: "X";
    }
  }


  .qr-blue-cube {
    @extend %cube-shared;
    background: #0072c7;

    &:before {
      content: "~";
    }
  }
}