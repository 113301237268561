body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /*font: 13px/19px Verdana, Helvetica, Arial, sans-serif;*/
  font: 14px/20px 'Frutiger Neue LT Pro Light','Helvetica-Light',Helvetica,Arial,sans-serif;
  font-weight: 500;
  background-color: #e6f0f8;
  color: #00294D;
}

#root {
  /*height: 100vh;*/
  height: 100%;
}

a {
  cursor: pointer;
  color: #0066B3 !important;
}

svg.closeIcon {
  color: #0066B3;
  font-size: 26px;
  margin-top: -9px;
}

h1, .h1 {
  font: 42px/48px 'Frutiger Neue LT Pro Light','Helvetica-Light',Helvetica,Arial,sans-serif;
  word-wrap: break-word;
  word-break: break-all;
  font-weight: 100;
}

h2, .h2 {
  margin-top: 1vh;
  margin-bottom: 2vh;
  word-wrap: anywhere;
  /*font: 21px/24px 'OfficinaSerifITCPro-Bold', 'Rockwell Bold', Rockwell, 'Courier Bold', Courier, Georgia, 'Liberation Serif', serif;*/
  font-size: 21px;

  svg {
    margin-right: 10px;
    color: #0066B3;
  }
}

h3, .h3 {
  clear: both;
  color: #002948;
  /*font: 18px/21px 'OfficinaSerifITCPro-Bold','Rockwell Bold',Rockwell,'Courier Bold',Courier,Georgia,'Liberation Serif',serif;*/
  font-size: 18px;
  margin: 0 0 9px 0;
  word-wrap: anywhere;
}

ol, ul, dl {
  margin-top: 10px;
  margin-bottom: 1rem;
  margin-left: 0.1rem;
  padding-left: 1rem;
}

ol {
  margin-left: 0.3rem;
}


ul li::marker {
  list-style: none;
  content: "\2013\A0";
}


.cursor-pointer {
  cursor: pointer;
}


.container {

  .content {
    margin: 0 10px;

    .welcome-message {
      line-height: 32px;
      margin-bottom: 6vh;
      font-family: 'Frutiger Neue LT Pro Book', 'Helvetica', Helvetica, Arial, sans-serif;

      h1 {
        color: #00294D;
        font-weight: 600;
        font-size: 32px;
        line-height: 40px;
      }

      & > div {
        /*color: #0066B3;*/
        color: #002948;
        font-style: italic;
        font-size: 24px;
        line-height: 29px;
      }

      .d-flex {
        & > div {
          flex: 3;
          font-style: normal;

          &:first-child, &:last-child{
            flex: 1 0 0;
          }
        }
      }
    }

    .button-scanapi {
      color: #FF6600;
    }

    .info-message {
      background-color: #fff;
      padding: 15px;
      margin-top: 6vh;
    }

    .info-message.error {
      margin-top: 2vh;
      margin-bottom: 6vh;

      h2, h2 svg {
        color: #ff0000;
      }

      svg.closeIcon {
        margin-top: 6px;
      }
    }

    .Logo {
      /*min-height: 80px;
      height: 5vh;*/
      margin-bottom: 9vh;
      width: 50vw;
      max-width: 350px;
    }

    .reload {
      margin-top: 20px;
      margin-bottom: 10px;

      .button-scanapi {
        color: #000;
      }

      .cursor-pointer {
        height: 70px;
      }

      svg {
        width: 50px;
      }
    }
  }

  .corner-ribbon {
    position: fixed;
    width: 100px;
    background: #ffffff;
    text-align: center;
    font-weight: bold;
    line-height: 50px;
    letter-spacing: 1px;
    color: #000000;
    box-shadow: 0 0 0 999px #ffffff;
    clip-path: inset(0 -100%);
    overflow: hidden;
  }

  .corner-ribbon.top-left {
    inset: 0 auto auto 0;
    transform: translate(-29.3%) rotate(-45deg);
    -webkit-transform: translate(-29.3%) rotate(-45deg);
    transform-origin: 100% 0;
  }

  .corner-ribbon.top-right {
    inset: 0 0 auto auto;
    transform: translate(29.3%) rotate(45deg);
    -webkit-transform: translate(29.3%) rotate(45deg);
    transform-origin: 0 0;
  }

  .corner-ribbon.green {
      background:#00ff00;
      color: #000000;
      box-shadow: 0 0 0 999px #00ff00;
  }

  .corner-ribbon.red {
    background: #ff0000;
    color: #f0f0f0;
   box-shadow: 0 0 0 999px #ff0000;
  }

  .corner-ribbon.yellow {
    background: #ffff00;
    color: #000000;
    box-shadow: 0 0 0 999px #ffff00;
   }

  .corner-ribbon.hidden {
    visibility: hidden
  }

}