.footer-navigation {
  margin-top: 2vh;
  margin-bottom: 2vh;
  color: #0066B3;

  & > div{
    padding: 10px;

    a {
      font-size: 0.8rem;
      color: #0066B3;
    }
  }
}