.block-screen{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.46);
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100000;
}
